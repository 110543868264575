
@use 'sass:color';
@import 'colours.scss';

footer {
    font-size: 90%;

    .content {
        background-color: $color-purple;
        background-image: linear-gradient(to right, $color-purple, color.adjust($color-purple, $lightness: 10%));
        border-radius: 25px;
        color: $color-white;
        margin: 0 30px;
        padding: 0 30px 5px;
        text-align: left;

        .logo {
            margin-bottom: 1.05rem;
            padding-top: 25px;
        }
    }

    .copyright {
        padding: 30px;
        text-align: center;
    }

    .text-yellow {
        color: $color-yellow;
        font-size: 120%;
        font-weight: 500;
        margin-bottom: .25rem;
    }

    a {
        color: $color-white;
        line-height: 1.5rem;

        &:hover {
            color: $color-white;
        }
    }

    @media print {
        display: none !important;
    }
}
