
@mixin button($background-colour, $hover-colour, $colour: $color-white, $font-weight: normal) {
    $border-colour: $background-colour;

    @if $background-colour == $color-white {
        $border-colour: $colour;
    }

    @if $background-colour != $border-colour {
        border-width: 2px;
    }

    background-color: $background-colour;
    border-color: $border-colour;
    color: $colour;
    font-weight: $font-weight;

    &:hover,
    &:active,
    &:not(:disabled):hover,
    &:not(:disabled):not(.disabled):active {
        background-image: none;
        @if $background-colour == $border-colour {
            background-color: $hover-colour;
        } @else {
            background-color: $background-colour;
            color: $hover-colour;
        }

        border-color: $hover-colour;
    }

    &:focus {
        background-color: $background-colour;
        border-color: $border-colour;
        color: $colour;
        box-shadow: none !important;
    }

    &.disabled,
    &:disabled {
        background-color: $color-grey-light;
        border-color: $color-grey-light;
        color: $color-grey;
    }
}
