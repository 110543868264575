
@use 'sass:color';
@import 'colours.scss';

.auth-dialog {
	background-color: $color-white;
	padding: 2rem;

	.inner {
		background: $color-white;
		margin: auto;
		max-width: 1000px;

		.header {
			background-color: $color-purple;
			color: $color-purple-lighter;
			padding: 1rem 2rem;
		}

		.content {
			padding: 1.5rem 2rem;

			.row > div {
				padding: auto 2rem;
			}

			.intro {
				border-right: 2px solid $color-grey-lighter;
			}
		}
	}
}

.auth-dialog .modal-dialog {
	box-shadow: 0 5px 15px color.adjust($color-black, $alpha: -0.5);
}

.auth-dialog .modal-header {
	background-color: $color-yellow;
	color: $color-white;
	font-size: 1.25rem;
	font-weight: bold;
}

.auth-dialog .modal-footer {
	background-color: $color-grey-lighter;
	justify-content: center;
}
