/****** New VF Colour Palette ******/
/* https://wiki.vflan.net/projects/new-geodivert-app */

$color-black: #000000;
$color-white: #FFFFFF;
$color-green: #339933;

$color-red: #CC0000;
$color-red-light: #FF0000;

$color-orange: #E67623;
$color-orange-light: #E98439;

$color-grey: #666666;
$color-grey-light: #A6A6A6;
$color-grey-lighter: #E5E5E5;
$color-grey-lightest: #F2F2F2;

$color-blue: #003366;
$color-blue-light: #748DA6;
$color-blue-lighter: #CFDAE5;
$color-blue-lightest: #E6ECF2;

$color-purple: #541b64;
$color-purple-light: #9B74A6;
$color-purple-lighter: #E1CFE5;
$color-purple-lightest: #F0E6F2;

$color-pink: #CC3366;
$color-pink-light: #D998AB;
$color-pink-lighter: #E5CFD6;
$color-pink-lightest: #F2E6EA;

$color-yellow: #ffcc33;
$color-yellow-light: #FFE28C;
$color-yellow-lighter: #FFE9A6;
$color-yellow-lightest: #FFF2CC;


/****** Proposed VF Colour Palette additions ******/

$color-white-dark: #FBFBFB;
$color-white-darker: #F6F6F6;
$color-grey-dark: #444444;
$color-green-light: #4EC21A;
$color-yellow-dark: #EEBF2D;
