
@import 'colours.scss';

.form-control.is-invalid,
.form-control.is-invalid:focus {
    border-color: $color-red !important;
    box-shadow: none !important;
    background-image: none !important;
    padding-right: 0.75rem !important;
}

.invalid-feedback {
    color: $color-red !important;
}
