
@import 'colours.scss';

.navbar {
    background-color: $color-purple;
    background-image: url('/blob-header.svg');
    background-repeat: no-repeat;
    background-position: -40px -370px;
    background-size: 160%;
    color: $color-white;
}

.navbar-light .navbar-brand {
    padding-left: 1.75rem;
    padding-right: 0.75rem;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-toggler,
.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:visited {
    color: $color-white !important;
    font-size: 115%;
    font-weight: normal;
}

.navbar-toggler {
    border: none !important;
    outline: none !important;
}

.maincontent {
    min-height: 400px;
    padding: 100px 30px 10px !important;
}
