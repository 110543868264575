
@import 'colours.scss';

.sort-code-input {
    input {
        text-align: center;
    }

    .input-group-text {
        border-radius: 0;
        border-left-width: 0;
        border-right-width: 0;
    }

    .is-invalid + .input-group-text {
        border-color: $color-red;
    }
}
