
@import 'mixins.scss';
@import 'colours.scss';

// Font Imports

@font-face {
    font-family: 'Arial Rounded';
    font-display: swap;
    src: url('/fonts/ARLRDBD.woff2') format('woff2'),
        url('/fonts/ARLRDBD.woff') format('woff'),
        url('/fonts/ARLRDBD.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-display: swap;
    src: url('/fonts/Poppins-Regular.woff2') format('woff2'),
        url('/fonts/Poppins-Regular.woff') format('woff'),
        url('/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-display: swap;
    src: url('/fonts/Poppins-Medium.woff2') format('woff2'),
        url('/fonts/Poppins-Medium.woff') format('woff'),
        url('/fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Poppins';
    font-display: swap;
    src: url('/fonts/Poppins-Bold.woff2') format('woff2'),
        url('/fonts/Poppins-Bold.woff') format('woff'),
        url('/fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
}


// Main Elements

body {
    color: $color-blue;
    font-family: 'Poppins', arial, helvetica, sans-serif;
    font-size: .9375em;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

section {
    margin: 1.5rem 1rem;
}

h1, h2, h3, h4, h5, h6 {
    color: $color-purple;
    font-family: 'Arial Rounded', 'Arial Black', Gadget, sans-serif;
    font-weight: bold;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.5rem;
}


// Buttons

.btn {
    border-radius: 8px;
}

.btn-primary {
    @include button($color-green, $color-green-light, $font-weight: 500);
}

.btn-secondary {
    @include button($color-white, $color-purple-light, $colour: $color-purple);
}


// Alerts

.alert {
    border-radius: 8px;
}

.alert-danger {
    background-color: $color-pink-lightest;
    border-color: $color-pink-lightest;
    color: $color-pink;
}

.alert-info {
    background-color: $color-blue-lightest;
    border-color: $color-blue-lightest;
    color: $color-blue;
}


// Cards

.card {
    border: none;
    border-radius: 8px;
}

.card.bg-info,
.card.bg-light {
    background-color: $color-grey-lightest !important;
}

.card table {
    margin-top: -1rem;
    margin-bottom: -1rem;
}

.card-title {
    padding: 1.5rem 1.25rem 0;
}


// Tabs

.nav-tabs .nav-item {
    border-color: $color-grey-lighter;
    margin-left: 3px;
    margin-right: 3px;
}

.nav-tabs .nav-item:first-child {
    margin-left: 0;
}

.nav-tabs .nav-item:hover {
    background-color: $color-white !important;
}

.nav-tabs .nav-item.active {
    background-color: $color-white;
    border-bottom-color: $color-white;
    color: $color-blue;
    font-weight: bold;
}

.nav-tabs .nav-item:not(.active) {
    background-color: $color-grey-lightest;
    color: $color-blue;
}

.tab-content {
    padding: 1.5rem 0 0;
}


// Tables

.table {
    color: $color-blue;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}

.table.table-sm {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
}

.table-hover {
    tbody {
        tr:hover {
            background-color: $color-grey-lightest;
            color: $color-blue;

            &.no-hover {
                background-color: white;
            }
        }
    }
}
